import { Navigate } from "react-router-dom";
import { useContext } from 'react'
import { SubscriptionContext, UserContext } from '../../context/user.context.jsx'

const StripePayment = () => {
    const { currentUser } = useContext(UserContext);
    const {isSubscribed} = useContext(SubscriptionContext)

    return (
        <div>
            {currentUser && isSubscribed ? (
                <Navigate to={"/home"} />
            ) : (
                <Navigate to={"/upgrade"}></Navigate>
            )}
        </div>
    )
}

export default StripePayment;
